import React from "react";
import { BasicLayout } from "../../../layouts/BasicLayout";
import styled from "styled-components";
import { StickyNavBar } from "../../../components/ui/StickyNavBar";
import { BottomNavSegment } from "../../../components/PageSegments";
import theme from "../../../util/theme";
import { TestimonialsSection } from "../../../components/PageSegments/offer/variant/TestimonialsSection";
import { StatsSection } from "../../../components/PageSegments/offer/variant/StatsSection";
import { HeroSection } from "../../../components/PageSegments/offer/variant/HeroSection";
import { LibrarySection } from "../../../components/PageSegments/offer/variant/LibrarySection";
import { TopBanner } from "../../../components/ui/TopBanner";
import { mobileSize } from "../../../util/variables";
import { useOffer } from "../../../hooks/useOffer";

export default (data: any) => {
  const { showExpiredBanner, priceData } = useOffer(data.location, "B");

  return (
    <BasicLayout>
      <Main>
        {showExpiredBanner && (
          <TopBanner show fixed={false}>
            <BannerText>
              {`The offer you selected has expired, but you can still get ${priceData.percentOff}% off.`}
            </BannerText>
          </TopBanner>
        )}
        <StickyNavBar initiallyWhite />
        <HeroSection priceData={priceData} />
        <TestimonialsSection priceData={priceData} />
        <StatsSection priceData={priceData} />
        <LibrarySection priceData={priceData} />
        <BottomNavSegment />
      </Main>
    </BasicLayout>
  );
};

const Main = styled.main`
  background: ${theme.WHITE_COLOR};
  h1 {
    font-family: ${theme.PRIMARY_FONT};
    font-size: 56px;
    font-weight: 400;
    line-height: 70px;
    text-align: center;
    color: ${theme.BLACK_COLOR};
  }
  @media ${mobileSize} {
    h1 {
      font-size: 36px;
      line-height: 45px;
    }
  }
`;

const BannerText = styled.p`
  margin: 0;
  line-height: 1.25;
  @media ${mobileSize} {
    font-size: 20px;
    /* margin-top: 60px; */
  }
`;
