import React from "react";
import { LibrarySegment } from "../../special-offer/prepayment";
import styled from "styled-components";
import { AppLink } from "../../../ui/AppLink";
import theme from "../../../../util/theme";
import { Wrapper } from "../../../ui";
import { FlexDiv } from "../../../ui";
import { mobileSize } from "../../../../util/variables";
import { OfferSegmentI } from "../../../../hooks/useOffer";
import { calculateDiscountedPrice } from "../../../../util/numbers";
import { offer_landing_page_click } from "../../../../services/mixpanel/discount-flow-events";

export const LibrarySection: React.FC<OfferSegmentI> = ({ priceData }) => {
  const { percentOff, annual_price, currency_symbol, coupon_id, price_id, trial } = priceData;
  const discountedPrice = calculateDiscountedPrice(Number(annual_price), percentOff ?? 0);

  return (
    <>
      <LibrarySegment />
      <LibraryFooter>
        <Wrapper>
          <FooterContentContainer>
            <FlexDiv direction="column" align="center" gap={20}>
              <AppLink
                dark
                to="/subscription/register?from=email"
                state={{
                  couponId: coupon_id,
                  priceId: price_id,
                  trial,
                }}
                onClick={() => offer_landing_page_click("library")}
              >
                {`Get ${percentOff}% Off`}
              </AppLink>
              <Terms>
                By continuing, you agree to Imprint&apos;s{" "}
                <a href="https://docs.google.com/document/d/1CF6JLDUCvQpdKegxKuM4Sm96QFlfwhuORv1sEGMfHPw/edit">
                  Terms of Use
                </a>{" "}
                and{" "}
                <a href="https://docs.google.com/document/d/1XwD2NWvdf4zZhRKYjBvPD4Q7S0f4-OhnaK8orj0GpJM/edit">
                  Privacy Policy
                </a>.
                This offer is valid for a limited time only for Imprint premium access. Starts at{" "}
                {
                  percentOff ?
                    `${currency_symbol}${discountedPrice} for the first year and 
                    then annually at ${currency_symbol}${annual_price} after that.` :
                    `${currency_symbol}${annual_price} each year.`
                } Cancel anytime in your account settings at least 24 hours in
                advance. This offer is only available for subscription purchases
                made via our website.
              </Terms>
            </FlexDiv>
          </FooterContentContainer>
        </Wrapper>
      </LibraryFooter>
    </>
  );
};

const LibraryFooter = styled.div`
  background-color: ${theme.PRIMARY_COLOR};
  padding: 20px 0 40px 0;
`;

const FooterContentContainer = styled.div`
  max-width: 640px;
  margin: auto;
  padding: 20px 0;
  @media ${mobileSize} {
    padding: 0;
  }
`;

const Terms = styled.p`
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  font-family: ${theme.SECONDARY_FONT};
  color: ${theme.WHITE_COLOR};
  text-align: center;
  margin: 0;
  a {
    color: ${theme.WHITE_COLOR};
  }
`;
