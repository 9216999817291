import styled from "styled-components";

interface FlexProps {
  direction?: "row" | "column" | "row-reverse" | "column-reverse";
  align?: "normal" | "stretch" | "center" | "flex-start" | "flex-end" | "baseline";
  justify?:
    | "left"
    | "right"
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-evenly"
    | "space-around"
    | "space-between";
  gap?: number;
}

export const FlexDiv = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "normal")};
  justify-content: ${props => (props.justify ? props.justify : "flex-start")};
  gap: ${props => (props.gap ? props.gap : 0)}px;
`;
