import { ProviderT } from "./acquisition-events";
import { trackEvent } from "./mixpanel";

const trackDiscountEvent = (event: string, properties: object = {}) => {
  const promo = localStorage.getItem("promo");
  trackEvent(event, { ...properties, Promo: promo }, "discount");
};

// CREATE ACCOUNT

export const discount_flow_appear = () => {
  trackDiscountEvent("WebDiscountFlow_Appear", {
    Screen: "CreateAccount",
  });
};

export const discount_flow_logInSwitch = () => {
  trackDiscountEvent("WebDiscountFlow_LogInSwitch_Tapped", {
    Screen: "CreateAccount",
  });
};

export const discount_flow_emailSwitch = () => {
  trackDiscountEvent("WebDiscountFlow_EmailSwitch_Tapped", {
    Screen: "CreateAccount",
  });
};

export const discount_flow_email_tapped = () => {
  trackDiscountEvent("WebDiscountFlow_EmailField_Tapped", {
    Screen: "CreateAccount",
  });
};

export const discount_flow_password_tapped = () => {
  trackDiscountEvent("WebDiscountFlow_PasswordField_Tapped", {
    Screen: "CreateAccount",
  });
};

export const discount_flow_googlesso_tapped = () => {
  trackDiscountEvent("WebDiscountFlow_GoogleSSO_Tapped", {
    Screen: "CreateAccount",
  });
};

export const discount_flow_applesso_tapped = () => {
  trackDiscountEvent("WebDiscountFlow_AppleSSO_Tapped", {
    Screen: "CreateAccount",
  });
};

export const discount_flow_create_account_start = (Provider: ProviderT) => {
  trackDiscountEvent("WebDiscountFlow_CreateAccountSubmit_Start", {
    Screen: "CreateAccount",
    Provider,
  });
};

export const discount_flow_create_account_fail = (Provider: ProviderT, errorMessage: string) => {
  trackDiscountEvent("WebDiscountFlow_CreateAccountSubmit_Fail", {
    Screen: "CreateAccount",
    Provider,
    error_description: errorMessage,
  });
};

export const discount_flow_create_account_success = (
  Provider: ProviderT,
  uid: string,
  email: string,
  isAnonymous: boolean,
) => {
  trackDiscountEvent("WebDiscountFlow_CreateAccountSubmit_Success", {
    Screen: "CreateAccount",
    auth_uid: uid,
    auth_email: email,
    auth_is_anonymous: isAnonymous,
    Provider,
  });
};

// LOGIN

export const discount_flow_login_start = () => {
  trackDiscountEvent("WebDiscountFlow_LoginSubmit_Start", {
    Screen: "Login",
  });
};

export const discount_flow_login_fail = (errorMessage: string) => {
  trackDiscountEvent("WebDiscountFlow_LoginSubmit_Fail", {
    Screen: "Login",
    error_description: errorMessage,
  });
};

export const discount_flow_login_success = (uid: string, email: string, isAnonymous: boolean) => {
  trackDiscountEvent("WebDiscountFlow_LoginSubmit_Success", {
    Screen: "Login",
    auth_uid: uid,
    auth_email: email,
    auth_is_anonymous: isAnonymous,
  });
};

// PAYMENT

export const discount_flow_payment_start = () => {
  trackDiscountEvent("WebDiscountFlow_PaymentPage_Start", {
    Screen: "Payment",
  });
};

export const discount_flow_payment_success = ({
  stripe_id,
  stripe_amount_total,
  stripe_currency,
  stripe_mode,
  stripe_payment_status,
  stripe_status,
  stripe_method_type,
}: {
  stripe_id: string;
  stripe_amount_total: number;
  stripe_currency: string;
  stripe_mode: string;
  stripe_payment_status: string;
  stripe_status: string;
  stripe_method_type: string[];
}) => {
  trackDiscountEvent("WebDiscountFlow_PaymentPage_Success", {
    Screen: "Payment",
    stripe_id,
    stripe_amount_total,
    stripe_currency,
    stripe_mode,
    stripe_payment_status,
    stripe_status,
    stripe_method_type,
  });
};

export const offer_landing_page_view = () => {
  trackDiscountEvent("WebOfferLandingPageView", {
    Screen: "OfferLandingPage",
  });
};

export const offer_landing_page_click = (section: string) => {
  trackDiscountEvent("WebOfferLandingPageClick", {
    Screen: "OfferLandingPage",
    section,
  });
};
