import { useState, useEffect, useContext } from "react";
import { offer_landing_page_view } from "../services/mixpanel/discount-flow-events";
import { COUPON_IDS, NEW_YEAR_COUPON_2024 } from "../util/variables";
import { setMixpanelProperties } from "../services/mixpanel/mixpanel";
import { navigate } from "gatsby";
import { LocalPriceContext } from "../providers/LocalPriceProvider";
import { GeolocationI, getGeolocation } from "../services/ipgeolocation";
import {
  US_PRICE_APP,
  US_PRICE_WEB,
  US_PRICE_WEB_TEST,
  getAppPriceDataFromLocation,
  getPriceDataFromLocation,
} from "../util/static-price-data";

type OfferDetailsT = {
  trial: boolean;
  coupon_id?: string;
  percentOff: number;
  app_price: boolean;
  startDate?: Date;
  endDate?: Date;
};

const OFFER_VARIANTS: Record<string, OfferDetailsT> = {
  Email7daDiscountWeb: {
    trial: true,
    coupon_id: COUPON_IDS.percent20,
    percentOff: 20,
    app_price: false,
  },
  EmailDiscountWeb: {
    trial: false,
    coupon_id: COUPON_IDS.percent30,
    percentOff: 30,
    app_price: false,
  },
  EmailDiscountApp: {
    trial: false,
    coupon_id: COUPON_IDS.percent30,
    percentOff: 30,
    app_price: true,
  },
  Email40DiscountWeb: {
    trial: false,
    coupon_id: COUPON_IDS.percent40,
    percentOff: 40,
    app_price: false,
  },
  Email40DiscountApp: {
    trial: false,
    coupon_id: COUPON_IDS.percent40,
    percentOff: 40,
    app_price: true,
  },
  Email7daApp: {
    trial: true,
    coupon_id: undefined,
    percentOff: 0,
    app_price: true,
  },
  kickstart2024: {
    trial: false,
    coupon_id: NEW_YEAR_COUPON_2024,
    percentOff: 50,
    app_price: false,
  },
  jumpstart2024: {
    trial: false,
    coupon_id: NEW_YEAR_COUPON_2024,
    percentOff: 50,
    app_price: true,
  },
  springweb2024: {
    trial: false,
    coupon_id: COUPON_IDS.percent40,
    percentOff: 40,
    app_price: false,
    endDate: new Date("2024-03-24T23:59:59-04:00"),
  },
  springapp2024: {
    trial: false,
    coupon_id: COUPON_IDS.percent40,
    percentOff: 40,
    app_price: true,
    endDate: new Date("2024-03-24T23:59:59-04:00"),
  },
  mayweb2024: {
    trial: false,
    coupon_id: COUPON_IDS.percent40,
    percentOff: 40,
    app_price: false,
    startDate: new Date("2024-05-24T00:01:00-04:00"),
    endDate: new Date("2024-05-29T23:59:59-04:00"),
  },
  mayapp2024: {
    trial: false,
    coupon_id: COUPON_IDS.percent40,
    percentOff: 40,
    app_price: true,
    startDate: new Date("2024-05-24T00:01:00-04:00"),
    endDate: new Date("2024-05-29T23:59:59-04:00"),
  },
  webjuly2024: {
    trial: false,
    coupon_id: COUPON_IDS.percent40,
    percentOff: 40,
    app_price: false,
    startDate: new Date("2024-07-03T00:01:00-04:00"),
    endDate: new Date("2024-07-09T23:59:59-04:00"),
  },
  appjuly2024: {
    trial: false,
    coupon_id: COUPON_IDS.percent40,
    percentOff: 40,
    app_price: true,
    startDate: new Date("2024-07-03T00:01:00-04:00"),
    endDate: new Date("2024-07-09T23:59:59-04:00"),
  },
  EmailDiscountWebSplit: {
    trial: false,
    coupon_id: COUPON_IDS.percent30,
    percentOff: 30,
    app_price: false,
  },
  appsep2024: {
    trial: false,
    coupon_id: COUPON_IDS.percent40,
    percentOff: 40,
    app_price: true,
    startDate: new Date("2024-08-28T00:01:00-04:00"),
    endDate: new Date("2024-09-04T23:59:00-04:00"),
  },
  websep2024: {
    trial: false,
    coupon_id: COUPON_IDS.percent40,
    percentOff: 40,
    app_price: false,
    startDate: new Date("2024-08-28T00:01:00-04:00"),
    endDate: new Date("2024-09-04T23:59:00-04:00"),
  },
  blackfridayweb2024: {
    trial: false,
    coupon_id: COUPON_IDS.percent40,
    percentOff: 40,
    app_price: false,
    endDate: new Date("2024-12-06T23:59:59-04:00"),
  },
  blackfridayapp2024: {
    trial: false,
    coupon_id: COUPON_IDS.percent40,
    percentOff: 40,
    app_price: true,
    endDate: new Date("2024-12-06T23:59:59-04:00"),
  },
};

type OfferPageVariant = "A" | "B";

type OfferT = {
  annual_price: string;
  trial: boolean;
  price_id: string;
  percentOff: number;
  coupon_id?: string;
  currency_symbol: string;
  link: string;
  default_offer?: boolean;
};

export interface OfferSegmentI {
  priceData: OfferT;
  handleRedirect?: () => void;
}

enum OfferState {
  active,
  expired,
  planned,
}

export const useOffer = (location: any, variant: OfferPageVariant) => {
  const urlParams = new URLSearchParams(location.search);
  const offerParam = urlParams.get("offer");
  const expiredParam = urlParams.get("expired");
  const priceTestOffer = offerParam === "EmailDiscountWebSplit" && variant === "B";

  const { currentPrice: localizedPrice } = useContext(LocalPriceContext);
  const [showExpiredBanner, setShowExpiredBanner] = useState(false);
  const [userLocation, setUserLocation] = useState<GeolocationI | null>(null);
  const [priceData, setPriceData] = useState<OfferT>({
    annual_price: priceTestOffer ? US_PRICE_WEB_TEST.annual_price : US_PRICE_WEB.annual_price,
    trial: true,
    price_id: priceTestOffer ? US_PRICE_WEB_TEST.price_id : US_PRICE_WEB.price_id,
    percentOff: 30,
    coupon_id: COUPON_IDS.percent30,
    link: `/subscription/register?from=email&cid=${COUPON_IDS.percent30}`,
    currency_symbol: US_PRICE_WEB.currency.symbol,
    default_offer: true,
  });

  const navigateToDefaultOffer = (code: string, originalOfferExpired: boolean) => {
    const platform = code.includes("web") ? "Web" : "App";
    const newOffer = `EmailDiscount${platform}`;
    const search = new URLSearchParams(location.search);
    search.set("offer", newOffer);
    if (originalOfferExpired) {
      search.set("expired", "true");
    }

    navigate(`${location.pathname}?${search.toString()}`);
  };

  const getOfferState = (code: string): OfferState => {
    const offerStartDate = OFFER_VARIANTS[code]?.startDate?.getTime();
    const offerEndDate = OFFER_VARIANTS[code]?.endDate?.getTime();
    const now = Date.now();

    if (offerStartDate && now < offerStartDate) {
      return OfferState.planned;
    }

    if (offerEndDate && now > offerEndDate) {
      return OfferState.expired;
    }

    return OfferState.active;
  };

  useEffect(() => {
    const updateOfferPriceData = (location: GeolocationI | null) => {
      const offerVariant = Object.keys(OFFER_VARIANTS).includes(offerParam ?? "")
        ? offerParam
        : "EmailDiscountWeb";
      // @ts-ignore
      const { app_price, ...rest } = OFFER_VARIANTS[offerVariant];
      if (location) {
        const localizedPrice = app_price
          ? getAppPriceDataFromLocation(location)
          : getPriceDataFromLocation(location, priceTestOffer);

        setPriceData({
          currency_symbol: localizedPrice.currency.symbol,
          annual_price: localizedPrice.annual_price,
          price_id: rest.trial
            ? localizedPrice.price_id
            : localizedPrice.price_id_no_trial ?? localizedPrice.price_id,
          ...rest,
        });
      } else {
        const US_Price = app_price
          ? US_PRICE_APP
          : priceTestOffer
          ? US_PRICE_WEB_TEST
          : US_PRICE_WEB;
        setPriceData({
          currency_symbol: US_Price.currency.symbol,
          annual_price: US_Price.annual_price,
          price_id: rest.trial
            ? US_Price.price_id
            : US_Price.price_id_no_trial ?? US_Price.price_id,
          ...rest,
        });
      }
    };

    const code = offerParam ?? "";
    switch (getOfferState(code)) {
      case OfferState.active:
        offer_landing_page_view();
        updateOfferPriceData(userLocation);
        break;
      case OfferState.expired:
        setShowExpiredBanner(true);
        navigateToDefaultOffer(code, true);
        break;
      case OfferState.planned:
        navigateToDefaultOffer(code, false);
        break;
      default:
        break;
    }
  }, [offerParam, localizedPrice, userLocation]);

  useEffect(() => {
    setShowExpiredBanner(expiredParam === "true");
  }, [expiredParam]);

  useEffect(() => {
    setMixpanelProperties({ OfferVariant: variant });
  }, [variant]);

  useEffect(() => {
    const fetchUserLocation = async () => {
      const location = await getGeolocation();
      if (location) {
        setUserLocation(location);
      }
    };

    fetchUserLocation();
  }, []);

  return { showExpiredBanner, priceData };
};
